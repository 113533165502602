/*******************************
         Site Overrides
*******************************/

.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  position: fixed;
   height: '100vh';
  overflow: auto;
}