/*******************************
     User Global Variables
*******************************/

@primaryColor: #002f4e;
@pageBackground: #eff0f1;

html.ios {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
html.ios,
html.ios body {
  height: initial !important;
}